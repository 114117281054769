import '../components/common/ahoy'
import '../components/common/swear_words_checker'

import SiteHeaderResizer from "../components/common/site_header_resizer"

window.SiteHeaderResizer = SiteHeaderResizer

import Vue from 'vue';

import '../components/common/sentry'

import VueRouter from 'vue-router';
import VueTurbolinks from 'vue-turbolinks';
import VueAhoy from '../components/common/vue_ahoy';
import VueI18n from "../components/common/vue_i18n";
import VueDynamicFields from "../components/common/vue_dynamic_fields";
import Paginate from 'vuejs-paginate'
import '../components/common/vue_component_initializer';

import store from '../components/store'

import VuePaginationComponent from '../components/common/pagination'
import VueClickOutsideDirective from '../components/common/directives/click_outside'

import VueStyleSelect from '../components/common/styleselect'
import FeedbackLink from '../components/common/feedback_link'
import SearchResultView from '../components/common/search_result_view'
import UserConsent from '../components/user/data_usage_agreement'

Vue.use(store);

Vue.use(VueAhoy);

Vue.use(VueI18n);
Vue.use(VueDynamicFields)

window.Vue = Vue;
window.Vue.use(VueClickOutsideDirective);
window.Vue.component('pagination', VuePaginationComponent);

window.Vue.use(VueRouter);
window.Vue.use(VueTurbolinks);

window.Vue.component('style-select', VueStyleSelect)
Vue.component('paginate', Paginate)

import ApolloClient from 'apollo-boost'

const apolloClient = new ApolloClient({
  uri: '/graphql'
});
import VueApollo from 'vue-apollo'

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
window.__apolloProvider = apolloProvider;

window.bus = new Vue();

var components = [
  {selector: "[data-vue-component='feedback-link-component']", component: FeedbackLink},
  {selector: "[data-vue-component=search-result-view]", component: SearchResultView},
  {selector: "[data-vue-component=user-personal-data-usage-agreement]", component: UserConsent}
]

window.Agiki.Common.VueComponentInitializer.registerComponents({components: components})

import Toasted from 'vue-toasted'
Vue.use(Toasted)


import UserConsentModal from '../components/user/data_usage_agreement_modal'

var pda_modal_components = [
  {selector: "[data-vue-component=user-personal-data-usage-agreement-modal]", component: UserConsentModal}
];

if (!window.Agiki) window.Agiki = {};


window.Agiki.initNewPDAModalApp = function(scope) {
  if (!scope) scope = document

  return window.Agiki.Common.VueComponentInitializer.initComponents({ components: pda_modal_components, scope: scope });
}